import { Variable } from "@/services/crud/Orchestration.service";
import { store } from "@/store";
import { guidRegex, isGuid } from "@/utils/type/guid";
import { VariableParser } from "./VariableParser";
import {
  DataModel,
  DataModelAttribute,
  UNKNOWN_DATA_MODEL_ID,
} from "@/services/datamodel/DataModel.model";
import { ProcessVariable } from "@/services/processvariables/ProcessVariables.model";
import { getVariableList } from "../../Values/ValueDataTypesHelper";

export default {
  VariableParser,
};

const state: any = store.state;

/**
 * @param fullCheck
 *  if true, check if each part (id) of variable is valid
 *  if false, check if first part (id) of variable is valid
 */
export const hasVariable = (
  value: any,
  fullCheck = true,
  extraVariables: ProcessVariable[] | Variable[] = []
) => {
  if (!isGuid(value)) {
    return false;
  }

  const variablesStr = value
    .split(" ")
    .filter((valueStr: string) => isGuid(valueStr));

  const matches = variablesStr.map((varStr: string) => {
    const ids = varStr.split(".");
    const variable: Variable = [
      ...state.processes.instanceVariables,
      ...state.processes.variables,
      ...store.getters.extraVariablesFlatList,
      ...state.document.variables,
      ...extraVariables,
    ].find((val: Variable) => val.id === ids[0]);

    const variableType: DataModel = store.getters.dataTypes.find(
      (dataType: DataModel) => variable?.dataType === dataType.id
    );

    if (!fullCheck && variableType) {
      return true;
    }

    const targetAttributeId = ids[ids.length - 1];
    if (variable && variable.id === targetAttributeId) {
      return true;
    }

    let match = false;
    let attributeType: DataModel;
    ids.slice(1).forEach((attributeId, index) => {
      const type = index === 0 ? variableType : attributeType;
      const attribute = type?.attributes?.find(
        (attr: DataModelAttribute) => attr.id === attributeId
      );

      if (attribute && attributeId === targetAttributeId) {
        match = true;
      }

      attributeType = store.getters.dataTypes.find(
        (dataType: DataModel) =>
          attribute?.dataTypeId === dataType.id &&
          (!dataType.isDataModel ||
            dataType.isProcesio ||
            (dataType.isDataModel && dataType.closestParentId === type.id))
      );
    });

    return match;
  });

  return matches.some((match: boolean) => match);
};

export const getVariable = (
  value: any,
  extraVariables: ProcessVariable[] | Variable[] = []
) => {
  if (!isGuid(value)) {
    return null;
  }

  const ids = value.split(".");
  if (!ids.length) {
    return null;
  }

  const variable: Variable = [
    ...state.processes.instanceVariables,
    ...state.processes.variables,
    ...store.getters.extraVariablesFlatList,
    ...state.document.variables,
    ...extraVariables,
  ].find((val: Variable) => val.id === ids[0]);

  const variableType: DataModel = store.getters.dataTypes.find(
    (dataType: DataModel) => variable?.dataType === dataType.id
  );

  return variable && variableType ? variable : null;
};

// check to filter context variables (now they have type 100 - context)
export const isContextVariable = (variable: Variable) => {
  const prefix = "contextVariable";
  return (
    variable.name.includes(prefix) && isGuid(variable.name.replace(prefix, ""))
  );
};

export const variableGuidRegexRaw =
  "([0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}(\\.[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12})*)";

export const placeholderRegex =
  /([x]{8}\-[x]{4}\-[x]{4}\-[x]{4}\-[a-zA-Z]{11}(\.[x]{8}\-[x]{4}\-[x]{4}\-[x]{4}\-[a-zA-Z]{11})*)/g;

export const hasPlaceholder = (value: string) => {
  return new RegExp(placeholderRegex).test(value);
};

export const lineBreakRegex = /(\r\n|\n|\r)/gm;

export const lineBreakSymbol = "↵";

export const getVariableDataType = (
  varTree: string,
  extraVariables: Variable[] = [],
  scopes: string[] = []
) => {
  const variables = getVariableList(extraVariables, scopes);

  const varTreeAsArray = varTree.trim().split(".");

  const variableId = varTreeAsArray[0];

  const variable = variables.find(
    (val: Variable) => val && val.id === variableId
  );

  if (variable) {
    let variableDataType: DataModel = store.getters.getDataTypeById(
      variable.dataType
    );

    let isVariableRecognized =
      variableDataType && !variableDataType.notPermitted;

    varTreeAsArray.slice(1).forEach((attrId) => {
      if (!isVariableRecognized) {
        return;
      }

      const attribute = (variableDataType?.attributes || []).find(
        (attr) => attr.id === attrId
      );

      const attributeDataType = store.getters.dataTypes.find(
        (type: DataModel) =>
          type.id === attribute?.dataTypeId &&
          (!type.isDataModel ||
            type.isProcesio ||
            (type.isDataModel && type.closestParentId === variableDataType?.id))
      );

      if (attributeDataType) {
        variableDataType = attributeDataType;
      } else {
        isVariableRecognized = false;
      }
    });

    return isVariableRecognized
      ? variableDataType
      : store.getters.getDataTypeById(UNKNOWN_DATA_MODEL_ID);
  }

  return;
};

export const isValueGuid = (
  value: string,
  variables: Variable[],
  variableScopes?: string[] | null
) => {
  return (
    new RegExp(new RegExp(variableGuidRegexRaw, "gm")).test(value) &&
    !!getVariableDataType(value, variables, variableScopes || [])
  );
};

export const splitValuePerVariable = (
  value: string,
  variables: Variable[],
  variableScopes?: string[] | null
) => {
  // add temporary space before each variable start to avoid variables concatenation
  (variables || []).forEach((variable) => {
    value = value.replaceAll(variable.id, " " + variable.id);
  });

  return value
    .split(new RegExp("\\s" + variableGuidRegexRaw, "gm"))
    .filter((val) => typeof val !== "undefined")
    .reduce((acc: string[], val) => {
      if (hasPlaceholder(val)) {
        acc.push(...val.split(placeholderRegex));
      } else {
        const lastValueIndex = acc.length - 1;
        const lastValue = lastValueIndex === -1 ? null : acc[lastValueIndex];
        // extract value to separate div if guid is related to variables
        // or there are not previous value
        // or previous value guid is related to variable
        if (
          isValueGuid(val, variables, variableScopes) ||
          lastValue === null ||
          isValueGuid(lastValue, variables, variableScopes)
        ) {
          let isReplaced = false;
          // for some reason this.guidRegex.test returns false on guids that start
          // with a dot .2d7d0370-b2e0-40eb-acba-d75526823944
          // if we find values of type .guid we should check if they exist
          // in the previous entry of matches so that if it matches we don't return it again
          // Ex: .2d7d0370-b2e0-40eb-acba-d75526823944 in 47fe5980-b480-48af-8d4c-cdebbf1c2303.2d7d0370-b2e0-40eb-acba-d75526823944
          if (
            guidRegex.test(val) &&
            !!lastValue &&
            isValueGuid(lastValue, variables, variableScopes) &&
            !isValueGuid(val, variables, variableScopes) &&
            lastValue.includes(val)
          ) {
            if (val[0] === ".") {
              val = val.substring(1);
            }
            val = val.replace(guidRegex, "");
            isReplaced = true;
          }

          if (!isReplaced || (isReplaced && val.trim().length > 0)) {
            acc.push(val.replaceAll(lineBreakRegex, lineBreakSymbol));
          }
        }
        // concat values if they both are just strings
        else if (typeof acc[lastValueIndex] === "string") {
          acc[lastValueIndex] = acc[lastValueIndex] + val;
        }
      }
      return acc;
    }, []);
};
