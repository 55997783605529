import { DecisionalCaseParameterGetter } from "@/modules/ProcessDesigner/ParameterGetter/ControParameterGetter/Decisional/DecisionalCaseParameterGetter";
import { createGuid } from "@/utils/type/guid";
import { cloneDeep } from "lodash";
import { Condition as DecisionalCondition } from "@/modules/ProcessDesigner/components/Controls/DecisionalManager/Decisional.model";
import { Node } from "@/modules/ProcessDesigner/components/PropertiesPanel/PropertiesPanel.model";
import { OperatorName } from "../actionlist/ActionList.service";

export enum LogicOperators {
  NONE = 0,
  AND = 1,
  OR = 2,
}

export enum RelationalOperators {
  NONE = 0,
  EQUALS = 1,
  DOES_NOT_EQUAL = 2,
  GREATER_THAN = 3,
  GREATER_THAN_OR_EQUAL_TO = 4,
  LESS_THAN = 5,
  LESS_THAN_OR_EQUAL_TO = 6,
  BETWEEN = 7,
  GROUP = 10,
}

export const relationalOperators = [
  {
    value: RelationalOperators.EQUALS,
    name: "EQUALS",
  },
  {
    value: RelationalOperators.DOES_NOT_EQUAL,
    name: "DOES_NOT_EQUAL",
  },
  {
    value: RelationalOperators.GREATER_THAN,
    name: "GREATER_THAN",
  },
  {
    value: RelationalOperators.GREATER_THAN_OR_EQUAL_TO,
    name: "GREATER_THAN_OR_EQUAL_TO",
  },
  {
    value: RelationalOperators.LESS_THAN,
    name: "LESS_THAN",
  },
  {
    value: RelationalOperators.LESS_THAN_OR_EQUAL_TO,
    name: "LESS_THAN_OR_EQUAL_TO",
  },
  {
    value: RelationalOperators.BETWEEN,
    name: "BETWEEN",
  },
];

interface Operator {
  variable: string;
  attribute: {
    id: string;
    nextAttribute: unknown;
  };
  value: string;
}

export interface Condition {
  id: number;
  uid?: string;
  operator: OperatorName | null;
  leftOperator: Operator | null;
  rightOperator: Operator | null;
  auxOperator: Operator | null;
  logicOperator: number;
  value: Condition[] | null;
  name?: string;
  checked?: boolean;
  rightOperandAsListRequired?: boolean;
  operandsAsListOptional?: boolean;
}

export const getNewCondition = (id: number): Condition => {
  return {
    id,
    uid: createGuid(),
    operator: null,
    leftOperator: {
      variable: "",
      attribute: {
        id: "",
        nextAttribute: null,
      },
      value: "",
    },
    rightOperator: {
      variable: "",
      attribute: {
        id: "",
        nextAttribute: null,
      },
      value: "",
    },
    auxOperator: {
      variable: "",
      attribute: {
        id: "",
        nextAttribute: null,
      },
      value: "",
    },
    logicOperator: 0,
    value: null,
  };
};

export const copyCondition = (originalCondition: Condition): Condition =>
  cloneDeep(originalCondition);

export const getConditionParams = (conditions: Condition[]) => {
  const paramGetter = new DecisionalCaseParameterGetter();

  return conditions.map((condition, index) =>
    paramGetter.prepareConditions(condition as DecisionalCondition, index)
  );
};

export const getTargetLine = (conditionNode: Node, id: string) => {
  if (!id) {
    return null;
  }

  return conditionNode.lineArray.find((line) => line.nodeEnd.id === id);
};

export const getTargetNode = (conditionNode: Node, id: string) => {
  return getTargetLine(conditionNode, id)?.nodeEnd;
};
