import { store } from "@/store";
import { ActionTypes as DataModelActionTypes } from "@/store/dataModel/DataModel.actions";
import { ActionTypes as UserActionTypes } from "@/store/user/User.actions";
import { ActionTypes as ResourceActionTypes } from "@/store/resource/Resource.actions";
import { ActionTypes as UIActionTypes } from "@/store/ui/UI.actions";
import { ActionTypes as UserPreferencesTypes } from "@/store/userPermissions/UserPermissions.actions";
import ls from "@/services/localStorage";
import {
  LsCampaignKey,
  LsAnnouncementsKey,
  LsMessageKey,
} from "@/store/ui/UI.store";
import { init as userpilotInit } from "./userpilot";
import { BannerType, BannerUnit } from "@/components/Banner/banner.model";
import AuthService from "../auth/Auth.service";
export default class ApplicationService {
  static async init(): Promise<unknown> {
    const promises = [];

    promises.push(
      ...[
        store.dispatch(UIActionTypes.LOAD_ANNOUNCEMENTS_DATA),
        store.dispatch(UIActionTypes.LOAD_CAMPAIGN_DATA),
      ]
    );

    if (!!store.getters.token) {
      if (
        !store.getters.tokenExpiresIn ||
        (!!store.getters.tokenExpiresIn &&
          new Date().getTime() > store.getters.tokenExpiresIn)
      ) {
        await AuthService.refreshToken();
      }

      userpilotInit();

      store.dispatch(UIActionTypes.SET_BANNER_TYPE, {
        key: BannerUnit.PROCESIO_ANNOUNCEMENT,
        value: ls.get(LsAnnouncementsKey) || [],
      });

      store.dispatch(UIActionTypes.SET_BANNER_TYPE, {
        key: BannerUnit.CAMPAIGN,
        value: ls.get(LsCampaignKey) || BannerType.CAMPAIGN,
      });

      promises.push(
        store.dispatch(UserActionTypes.LOAD_EVENTS, true),
        (async () => {
          await store.dispatch(UserActionTypes.LOAD_USER_ID);
          await store.dispatch(UserActionTypes.LOAD_WORKSPACES);
          await store.dispatch(UserPreferencesTypes.LOAD_PERMISSIONS_DATA);
          await store.dispatch(ResourceActionTypes.LOAD_TIME);
          await store.dispatch(UIActionTypes.LOAD_USER_PREFERENCES);
          await store.dispatch(DataModelActionTypes.LOAD_INTERNAL_DATA_MODELS);
        })()
      );
    } else {
      ls.remove(LsMessageKey);
      ls.remove(LsAnnouncementsKey);
      ls.remove(LsCampaignKey);

      promises.push(
        store.dispatch(DataModelActionTypes.LOAD_INTERNAL_DATA_MODELS)
      );
    }

    return Promise.all(promises);
  }
}
