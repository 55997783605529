import RestService, { PaginatedResponse } from "../Rest.service";
import RestUrls from "../Rest.paths";

import {
  DataModel,
  DataModelAttributeBase,
  DataModelBase,
  DataTypeToChangeDTO,
  GenerateDataModelDTO,
  GenerateFileDataModelDTO,
  CreateDataModelDTO,
  DataModelType,
} from "./DataModel.model";
import { searchDataModelMock } from "./Mockups.service";
import { store } from "@/store";
import { config } from "@/config";

export default class DataModelService {
  static certificate = config.protocol;

  static baseUrl = config.api;

  static searchDataModel(search: string): Promise<{ payload: DataModel[] }> {
    const searchDataModel = new Promise<{ payload: DataModel[] }>((resolve) => {
      setTimeout(() => {
        resolve({ payload: searchDataModelMock });
      }, 1000);
    });

    return searchDataModel || search;
  }

  /////////////////////////

  static createDataModel(dataModel: Partial<CreateDataModelDTO>) {
    return RestService.post<CreateDataModelDTO>({
      url: RestUrls.API.DATATYPE.DEFAULT,
      data: dataModel,
      isAuth: true,
    });
  }

  static createPrivateDataModel(dataModel: Partial<CreateDataModelDTO>) {
    return RestService.post<CreateDataModelDTO>({
      url: RestUrls.API.DATATYPE.PRIVATE,
      data: dataModel,
      isAuth: true,
    });
  }

  static async generateDataModel(
    dataModel: GenerateDataModelDTO | GenerateFileDataModelDTO
  ) {
    const isFile = (dataModel as GenerateFileDataModelDTO).file instanceof File;

    if (!isFile) {
      return RestService.post<string>({
        url: RestUrls.API.DATATYPE.GENERATE,
        data: dataModel as GenerateDataModelDTO,
        isAuth: true,
      });
    }

    const formData = new FormData();
    formData.append("name", (dataModel as GenerateFileDataModelDTO).name);
    formData.append(
      "displayName",
      (dataModel as GenerateFileDataModelDTO).displayName
    );
    formData.append(
      "file",
      (dataModel as GenerateFileDataModelDTO).file as File
    );

    const headers = new Headers();
    headers.set("Accept", "application/json");
    headers.set("Authorization", `Bearer ${store.getters.token}`);
    // headers.set(
    //   "workspace",
    //   store.getters.activeWorkspaceKey === DefaultWorkspaceKey
    //     ? ""
    //     : store.getters.activeWorkspaceKey
    // );
    headers.set("workspaceId", store.getters.activeWorkspaceIdStringified);

    const response = await fetch(
      `${this.certificate}://${this.baseUrl}/${RestUrls.API.DATATYPE.GENERATE_FROM_FILE}`,
      {
        method: "POST",
        headers,
        body: formData,
      }
    );

    return response.ok
      ? {
          content: await response.json(),
        }
      : null;
  }

  static updateDataModel(dataModel: Partial<DataModelBase>) {
    return RestService.put<string>({
      url: RestUrls.API.DATATYPE.UPDATE,
      data: dataModel,
      isAuth: true,
    });
  }

  static updateDataModelName(dataModel: Partial<DataModelBase>) {
    return RestService.put<string>({
      url: RestUrls.API.DATATYPE.UPDATE_NAME,
      data: dataModel,
      isAuth: true,
    });
  }

  static async loadDataModelList(pageNumber = 0, pageItemCount = 0) {
    return RestService.get<PaginatedResponse<DataModel>>({
      url: RestUrls.API.DATATYPE.LIST_PAGINATED(pageNumber, pageItemCount),
      isAuth: true,
    });
  }

  static async loadCustomDataModelList(pageNumber = 0, pageItemCount = 0) {
    return RestService.get<PaginatedResponse<DataModel>>({
      url: RestUrls.API.DATATYPE.LIST_CUSTOM_PAGINATED(
        pageNumber,
        pageItemCount
      ),
      isAuth: true,
    });
  }

  static async loadCustomRestrictedDataModelList(
    pageNumber = 0,
    pageItemCount = 0
  ) {
    return RestService.get<PaginatedResponse<DataModel>>({
      url: RestUrls.API.DATATYPE.LIST_CUSTOM_RESTRICTED(
        pageNumber,
        pageItemCount
      ),
      isAuth: true,
    });
  }

  static loadInternalDataModelList(pageNumber = 0, pageItemCount = 0) {
    return RestService.get<PaginatedResponse<DataModel>>({
      url: RestUrls.API.DATATYPE.LIST_INTERNAL_PAGINATED(
        pageNumber,
        pageItemCount
      ),
      isAuth: !!store.getters.token,
    });
  }

  static deleteDataModel(modelId: string) {
    return RestService.delete<string>({
      url: RestUrls.API.DATATYPE.MODELID(modelId),
      isAuth: true,
    });
  }

  static loadDataModel(
    modelId: string,
    type: DataModelType = DataModelType.INTERNAL
  ) {
    return RestService.get<DataModel>({
      url: RestUrls.API.DATATYPE.MODELID(modelId, type),
      isAuth: true,
    });
  }

  static saveAttribute(dataModelId: string, attribute: DataModelAttributeBase) {
    const method = attribute.id ? "put" : "post";
    return RestService[method]<DataModelAttributeBase>({
      url: RestUrls.API.DATATYPE.ATTRIBUTE(dataModelId),
      isAuth: true,
      data: attribute,
    });
  }

  static deleteAttribute(dataModelId: string, attributeId: string) {
    return RestService.delete<string>({
      url: RestUrls.API.DATATYPE.ATTRIBUTE(dataModelId, attributeId),
      isAuth: true,
    });
  }

  static changeToPublicDataModel(dataTypeToChange: DataTypeToChangeDTO) {
    return RestService.post<string>({
      url: RestUrls.API.DATATYPE.CHANGE_TO_PUBLIC,
      isAuth: true,
      data: dataTypeToChange,
    });
  }

  static publicCloneDataModel(dataTypeToChange: DataTypeToChangeDTO) {
    return RestService.post<string>({
      url: RestUrls.API.DATATYPE.CLONE_PUBLIC,
      isAuth: true,
      data: dataTypeToChange,
    });
  }

  static getCount() {
    return RestService.get<number>({
      url: RestUrls.API.DATATYPE.COUNT,
      isAuth: true,
    });
  }
}
