var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{class:{
    'pup-c-group': true,
    'pup-c--holder': _vm.level === 0,
    'pds-u-m--b--16': _vm.level === 0,
    'pds-u-m--t--16': _vm.level > 0 && _vm.index === 0,
    'pup-c-group--check': _vm.isChecked || _vm.isEverythingSelected,
  },attrs:{"data-level":_vm.level}},[_c('div',{staticClass:"pup-c-group--head pup-c-group--row",class:{
      'pds-u-m--r--4 pds-u-m--l--4 pds-u-m--t--4':
        _vm.isChecked || _vm.isEverythingSelected,
    }},[(!_vm.isAnyConditionChecked)?_c('div',{staticClass:"pup-c-group--input"},[(_vm.level > 0 && !_vm.disabled)?_c('pds-icon',{staticClass:"pup-c-case-configuration--icon pup-c-case-configuration--drag-handle",attrs:{"icon":"drag_indicator","isOutlined":true}}):_vm._e(),_vm._v(" "),_c('div',{on:{"mouseover":function($event){_vm.hover = true},"mouseleave":function($event){_vm.hover = false}}},[(!_vm.hover && !_vm.isChecked && _vm.level > 0)?_c('span',{staticClass:"pup-c-group--index pds-u-m--r--4"},[_vm._v(_vm._s(_vm.index + 1))]):_vm._e(),_vm._v(" "),(_vm.hover || _vm.isChecked)?_c('pds-checkbox',{staticClass:"pup-c-group--index pds-u-m--r--4",attrs:{"variation":"tiny"},model:{value:(_vm.condition.checked),callback:function ($$v) {_vm.$set(_vm.condition, "checked", $$v)},expression:"condition.checked"}}):_vm._e()],1),_vm._v(" "),(!_vm.isEditName)?_c('p',{staticClass:"pds-u-subtitle--2 pds-u-m--r--4"},[_vm._v("\n        "+_vm._s(_vm.level === 0 ? "Advanced Filtering Builder" : _vm.name)+"\n      ")]):_c('pds-input',{ref:"renameInput",attrs:{"status":_vm.errorStatus,"maxlength":32,"variation":"small","disabled":_vm.disabled},on:{"blur":_vm.changeIsEditName},model:{value:(_vm.condition.name),callback:function ($$v) {_vm.$set(_vm.condition, "name", $$v)},expression:"condition.name"}}),_vm._v(" "),(_vm.level > 0 && !_vm.isEditName && !_vm.disabled)?_c('pds-icon',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
          content: _vm.language.rename,
        }),expression:"{\n          content: language.rename,\n        }"}],staticClass:"pup-c-group--row--button material-icons--grey",attrs:{"icon":"edit"},on:{"click":_vm.changeIsEditName}}):_vm._e()],1):_c('div',{staticClass:"pup-c-group--input pup-c-group--input--level"},[_c('p',{staticClass:"pds-u-body--2"},[_vm._v("\n        "+_vm._s((_vm.conditionsSelected > 0
            ? _vm.conditionsSelected + " " + _vm.language.condition
            : "") +
          (_vm.conditionsSelected > 0 && _vm.groupSelected > 0 ? " and " : "") +
          (_vm.groupSelected > 0 ? _vm.groupSelected + " " + _vm.language.groups : ""))+"\n      ")]),_vm._v(" "),(!_vm.disabled)?_c('pds-button',{staticClass:"pds-u-m--l--24",attrs:{"type":"link","color":"danger","size":"small"},on:{"click":_vm.deselectLevel}},[_vm._v(_vm._s(_vm.language.clear))]):_vm._e(),_vm._v(" "),(!_vm.disabled)?_c('div',{staticClass:"pup-c-group--actions"},[(_vm.level === 0)?_c('pds-button',{attrs:{"type":"solid","color":"danger","size":"small"},on:{"click":_vm.deleteSelected}},[_vm._v(_vm._s(_vm.language.delete))]):_vm._e(),_vm._v(" "),(_vm.level === 0 && _vm.conditionsSelected === 0)?_c('pds-button',{attrs:{"type":"outlined","color":"subtle","size":"small"},on:{"click":_vm.ungroupSelectedGeneral}},[_vm._v(_vm._s(_vm.language.condition_builder_defaul_ungroup))]):_vm._e(),_vm._v(" "),(
            _vm.level === 0 &&
            _vm.isSelectedOnSameLevel &&
            _vm.groupSelected + _vm.conditionsSelected > 1
          )?_c('pds-button',{attrs:{"type":"outlined","color":"subtle","size":"small"},on:{"click":_vm.groupSelectedGeneral}},[_vm._v(_vm._s(_vm.language.condition_builder_defaul_create))]):_vm._e()],1):_vm._e()],1),_vm._v(" "),(!_vm.disabled)?_c('div',{staticClass:"pup-c-group--buttons"},[_c('div',{staticClass:"pup-c-group--buttons"},[(_vm.level > 0)?_c('pds-button',{attrs:{"type":"outlined","color":"subtle","size":"small"},on:{"click":function($event){return _vm.$emit('ungroup', _vm.index)}}},[_vm._v(_vm._s(_vm.language.condition_builder_defaul_ungroup))]):_vm._e(),_vm._v(" "),(_vm.level > 0)?_c('pds-button',{staticClass:"pds-u-m--l--12",attrs:{"type":"solid","color":"danger","size":"small"},on:{"click":function($event){return _vm.$emit('remove-condition', _vm.index)}}},[_vm._v(_vm._s(_vm.language.condition_builder_defaul_delete))]):_vm._e()],1)]):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"pup-c-condition-builder"},[_c('draggable',{attrs:{"list":_vm.condition.value,"group":{ name: 'g1' },"scroll-sensitivity":200,"forceFallback":true,"move":_vm.onConditionMove,"options":{ disabled: _vm.disabled },"handle":".pup-c-case-configuration--drag-handle"}},_vm._l((_vm.conditions),function(condition,key){return _c(_vm.getComponent(condition),{key:condition.uid,tag:"component",staticClass:"pup-c-group--item",class:[
          _vm.conditions &&
            _vm.disabled &&
            key === _vm.conditions.length - 1 &&
            !!condition.value &&
            'pds-u-m--b--16',
        ],attrs:{"index":key,"settings":_vm.settings,"parent":_vm.parent,"processVariables":_vm.processVariables,"form":_vm.form,"condition":condition,"conditionsLength":_vm.conditions.length || 0,"level":_vm.getNextLevel(condition),"status":_vm.status,"name":condition.name,"initialStatus":_vm.initialStatus,"disabled":_vm.disabled,"hasLogicOperatorDivider":_vm.conditions &&
          !condition.value &&
          _vm.conditions[key + 1] &&
          !_vm.conditions[key + 1].value,"operators":_vm.operators},on:{"remove-condition":_vm.removeCondition,"ungroup":_vm.ungroup}})}),1),_vm._v(" "),(!_vm.disabled)?_c('pup-cb-adder',{attrs:{"level":_vm.level},on:{"add-condition":_vm.addCondition,"add-group":_vm.addGroup}}):_vm._e(),_vm._v(" "),(_vm.canShowLogicOperator(_vm.index))?_c('pup-logic-operator',{attrs:{"disabled":_vm.disabled,"hasDivider":false},model:{value:(_vm.condition.logicOperator),callback:function ($$v) {_vm.$set(_vm.condition, "logicOperator", $$v)},expression:"condition.logicOperator"}}):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }