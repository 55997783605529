import { MutationTree } from "vuex";
import { DataModelState } from "./DataModel.store";
import { cloneDeep } from "lodash";
import {
  convertAttributeToDataModel,
  DataModel,
  DataModelAttribute,
  isCustomDataModel,
} from "@/services/datamodel/DataModel.model";
import Vue from "vue";

export enum MutationTypes {
  ADD_DATA_TYPE = "ADD_DATA_TYPE",
  DELETE_DATA_TYPE = "DELETE_DATA_TYPE",
  RESET_CUSTOM_DATA = "RESET_CUSTOM_DATA",
  RESET_DATA = "RESET_DATA",
}

export type Mutations<S = DataModelState> = {
  [MutationTypes.ADD_DATA_TYPE](
    state: S,
    payload: {
      data: DataModel | DataModel[];
      context?: {
        // workspace, which data models were loaded for;
        // note: it may be different from the current workspace because on WS checkout new request can be fulfilled faster than old one, and resources can be overridden
        workspaceId: string | null;
      };
    }
  ): void;
  [MutationTypes.DELETE_DATA_TYPE](state: S, payload: string): void;
  [MutationTypes.RESET_CUSTOM_DATA](state: S): void;
  [MutationTypes.RESET_DATA](state: S): void;
};

export const mutations: MutationTree<DataModelState> & Mutations = {
  [MutationTypes.ADD_DATA_TYPE](state, { data, context }) {
    // do not add data types if context is presented and does not match
    if (
      context &&
      typeof context.workspaceId !== "undefined" &&
      context.workspaceId !== this.getters.activeWorkspaceId
    ) {
      return;
    }

    const existingTypes = state.dataTypes;

    const addDataTypes = (types: DataModel[] | DataModelAttribute[]) => {
      types.forEach((type: DataModel | DataModelAttribute | null) => {
        if (!!(type as DataModelAttribute)?.parentDataTypeId) {
          type = convertAttributeToDataModel(type as DataModelAttribute);
        }

        if (!type) {
          return;
        }

        type.scopes = Array.isArray(type.scopes) ? type.scopes : [];

        const index = existingTypes.findIndex(
          (existingDt) =>
            existingDt.id === type?.id &&
            (existingDt.isProcesio ||
              existingDt.closestParentId === type?.closestParentId)
        );
        if (index === -1) {
          existingTypes.push(type);
        } else if (index !== -1 && !type.isProcesio) {
          Vue.set(existingTypes, index, cloneDeep(type));
        }

        if (type.attributes?.length && isCustomDataModel(type)) {
          addDataTypes(type.attributes);
        }
      });
    };

    const dataTypes = Array.isArray(data) ? data : [data];
    addDataTypes(dataTypes);
  },
  [MutationTypes.DELETE_DATA_TYPE](state, payload: string) {
    const index = state.dataTypes.findIndex(
      (existingDt) => existingDt.id === payload
    );
    if (index === -1) {
      return;
    }
    state.dataTypes.splice(index, 1);
  },

  [MutationTypes.RESET_CUSTOM_DATA](state) {
    state.dataTypes = state.dataTypes.filter((item) => !!item.isProcesio);
  },

  [MutationTypes.RESET_DATA](state) {
    state.dataTypes = [];
  },
};
