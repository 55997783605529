import Vue from "vue";
import { Component } from "vue-property-decorator";

import {
  Flow,
  Variable as IVariable,
  VariableType,
} from "@/services/crud/Orchestration.service";
import { ProcessVariable } from "@/services/processvariables/ProcessVariables.model";
import {
  DataModel,
  DataModelAttribute,
  getUnknownDataModel,
  UNKNOWN_DATA_MODEL_ID,
} from "@/services/datamodel/DataModel.model";
import {
  FOREACH_LIST_ITEM_SETTING,
  FOREACH_LIST_SETTING,
} from "@/modules/ProcessDesigner/components/PropertiesPanel/Utils/Settings";
import { cloneDeep } from "lodash";
import { getVariableList } from "../../Values/ValueDataTypesHelper";
import { SettingTab } from "@/modules/ProcessDesigner/components/PropertiesPanel/PropertiesPanel.model";
import { createGuid } from "@/utils/type/guid";
import { NonPrimitives } from "@/utils/dataTypeMapper";
import UserPermissionsGuard from "@/services/user/permissions/UserPermissionsGuard";
import {
  ProcesioEntityType,
  RoleType,
} from "@/services/user/permissions/UserPermissions.model";
import { getVariableDataType, hasPlaceholder } from ".";

@Component
export class Variable extends Vue {
  variableRegex =
    /([0-9a-fA-F]{8}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{12}(\.[0-9a-fA-F]{8}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{12})*)/g;

  hasVariable(value: string) {
    /**
     * For some reason testing against the same regex value (this.variableRegex.test)
     * returns false on value on which it should return true. For that reason we test
     * a new Regexp each time
     * TODO: reaserch above behaviour
     */
    return new RegExp(this.variableRegex).test(value);
  }

  hasPlaceholder(value: string) {
    return hasPlaceholder(value);
  }

  getVariableDataType(
    varTree: string,
    extraVariables: ProcessVariable[] | IVariable[] = [],
    scopes: string[] = []
  ) {
    return getVariableDataType(varTree, extraVariables, scopes);
  }

  isVariableList(
    varTree: string,
    extraVariables: IVariable[] | ProcessVariable[] = [],
    scopes: string[] = []
  ) {
    const varTreeAsArray = varTree.split(".");

    const variableId = varTreeAsArray[0];

    const variable = getVariableList(
      extraVariables as IVariable[],
      scopes
    ).find((val: IVariable) => val && val.id === variableId);

    if (variable) {
      if (varTreeAsArray.length === 1) {
        return variable.isList;
      }

      let variableDataType: DataModel = this.$store.getters.dataTypes.find(
        (type: DataModel) => type.id === variable.dataType
      );

      let attribute: DataModelAttribute | undefined;

      varTreeAsArray.slice(1).forEach((attrId) => {
        attribute = (variableDataType?.attributes || []).find(
          (attr) => attr.id === attrId
        );

        const attributeDataType = this.$store.getters.dataTypes.find(
          (type: DataModel) =>
            type.id === attribute?.dataTypeId &&
            (!type.isDataModel ||
              type.isProcesio ||
              (type.isDataModel &&
                type.closestParentId === variableDataType?.id))
        );

        if (attributeDataType) {
          variableDataType = attributeDataType;
        }
      });

      if (attribute) {
        return attribute.isList;
      }

      return false;
    }

    return false;
  }

  mapFlowVariables(process: Flow) {
    // Some older instances might not have a system variable
    // Add it manually
    // TODO: think how to find out if this is needed anymore
    let hasSystemVariable = false;

    if (!process.variables) {
      return [];
    }

    const flowVariables = (cloneDeep(process.variables) || []).map(
      (variable: IVariable) => {
        // check if variable is readonly
        let isReadonly = false;
        let isConst = false;
        if (process.webhooks && process.webhooks.length > 0) {
          // set variable readonly if there is a webhook with this variable
          isReadonly = process.webhooks
            .reduce((variableIds: string[], webhook) => {
              variableIds.push(
                ...(webhook.webhookVariables || []).map(
                  (item) => item.variableId
                )
              );
              return variableIds;
            }, [])
            .includes(variable.id);
          isConst = isReadonly;
        }

        variable.isReadonly = isReadonly;
        variable.isConst = isConst;

        if (variable.isError === true) {
          variable.isConst = true;
        }

        if (variable.type === VariableType.PROCESS) {
          variable.isReadonly = true;
          variable.isConst = true;

          hasSystemVariable = true;
        }

        variable.parentVariableId = null;
        const contextId = variable.contextId;
        if (contextId) {
          const contextAction = (process.actions || []).find(
            (action) => action.id === contextId
          );
          if (!contextAction) {
            return variable;
          }

          // set parentVariableId for list item variable
          if (contextAction.type === "area") {
            const listParam = contextAction.parameters.find(
              (param) => param.tabPropertyId === FOREACH_LIST_SETTING
            );
            const listItemParam = contextAction.parameters.find(
              (param) => param.tabPropertyId === FOREACH_LIST_ITEM_SETTING
            );

            if (
              listParam &&
              listParam.variable?.length === 1 &&
              listParam.variable[0].variableId &&
              listItemParam &&
              listItemParam.variable?.length === 1 &&
              listItemParam.variable[0].variableId &&
              variable.id === listItemParam.variable[0].variableId
            ) {
              variable.isReadonly = true;
              variable.parentVariableId = listParam.variable[0].variableId;
            }
          }
        }

        return variable;
      }
    );

    if (!hasSystemVariable) {
      flowVariables.push({
        id: createGuid(),
        dataType: NonPrimitives.SYSTEM_VARIABLE,
        name: "ProcessInfo",
        type: VariableType.PROCESS,
        isList: false,
        isConst: true,
        isReadonly: true,
        isError: false,
        defaultValue: undefined,
      });
    }

    return flowVariables;
  }

  isVariableUsedInConfiguration(
    variable: IVariable,
    configuration: SettingTab[]
  ) {
    let isUsed = false;

    (configuration || []).forEach((tab) => {
      if (isUsed) {
        return;
      }

      tab.settings.forEach((setting) => {
        if (isUsed) {
          return;
        }

        if (setting && typeof setting === "object") {
          const stringifiedSetting = JSON.stringify(setting);
          if (stringifiedSetting.includes(variable.id) && !isUsed) {
            isUsed = true;
            return;
          }
        }
      });
    });

    return isUsed;
  }
}
