var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('pds-sidepanel',{class:[
    'pup-c-case-configuration',
    _vm.isFullScreen && 'pup-c-case-configuration--fullscreen',
  ],attrs:{"title":"Case Configuration","isFooter":false},on:{"close":_vm.closeSidePanel}},[_c('div',{staticClass:"pup-c-case-configuration--wrapper"},[(Array.isArray(_vm.cases) && _vm.cases.length)?_c('pup-case-selector',{attrs:{"value":_vm.value,"node":_vm.parent,"cases":_vm.cases,"settings":_vm.settings,"disabled":_vm.disabled},on:{"selected":function($event){return _vm.$emit('caseSelected', $event)}}}):_vm._e(),_vm._v(" "),_c('div',{staticClass:"pup-c-case-configuration--wrapper--config"},[_c('pds-collapsable',{staticClass:"pds-u-m--b--16",attrs:{"title":"Details"}},[_c('pds-input',{attrs:{"label":"Name","required":true,"maxlength":28,"status":_vm.value.status ? _vm.value.status.name : null,"disabled":_vm.disabled},on:{"blur":function($event){return _vm.fieldBlurHandler('name')}},nativeOn:{"!focus":function($event){return _vm.fieldFocusHandler('name')}},model:{value:(_vm.value.name),callback:function ($$v) {_vm.$set(_vm.value, "name", $$v)},expression:"value.name"}})],1),_vm._v(" "),_c('condition-group',{attrs:{"settings":_vm.settings,"parent":_vm.parent,"processVariables":_vm.processVariables,"form":_vm.form,"status":_vm.value.status,"condition":{
          value: _vm.conditions,
          name: 'Advanced Filtering Builder',
        },"initialStatus":_vm.initialStatus,"disabled":_vm.disabled}}),_vm._v(" "),_c('pds-collapsable',{staticClass:"pds-u-m--b--16",attrs:{"title":"Target"}},[_c('div',{staticClass:"pup-c-case-configuration--targets"},[_c('pds-select',{staticClass:"full-wdith",attrs:{"label":"Go To","placeholder":"Select target","status":_vm.value.status ? _vm.value.status.target : null,"options":_vm.targetOptions,"showValidationMessage":false,"disabled":_vm.disabled,"clearable":!!_vm.value.target},on:{"blur":function($event){return _vm.fieldBlurHandler('target')}},nativeOn:{"!focus":function($event){return _vm.fieldFocusHandler('target')}},model:{value:(_vm.value.target),callback:function ($$v) {_vm.$set(_vm.value, "target", $$v)},expression:"value.target"}})],1)])],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }