import { ActionTree, ActionContext } from "vuex";
import { FormState } from "./Form.store";
import { Mutations, MutationTypes } from "./Form.mutations";
import { escapeString } from "@/utils/type/string";
import {
  FormBuilderMode,
  FormBuilderUserRole,
} from "@procesio/procesio-ui-builder";
import { FormTemplateStatus } from "@/services/form/FormTemplate.model";

export enum ActionTypes {
  SET_ID = "SET_ID",
  SET_INSTANCE_ID = "SET_INSTANCE_ID",
  SET_NAME = "SET_NAME",
  SET_MODE = "SET_MODE",
  SET_STATUS = "SET_STATUS",
  SET_USERS = "SET_USERS",
  SET_ACTIVE_STATE = "SET_ACTIVE_STATE",
  SET_SAVED_STATE = "SET_SAVED_STATE",
  SET_HIDE_BRANDING_STATE = "SET_HIDE_BRANDING_STATE",
  RESET_DATA = "RESET_DATA",
  SET_SUBMITTER = "SET_SUBMITTER",
}

type AugmentedActionContext = {
  commit<K extends keyof Mutations>(
    key: K,
    payload?: Parameters<Mutations[K]>[1]
  ): ReturnType<Mutations[K]>;
} & Omit<ActionContext<FormState, FormState>, "commit">;

export interface Actions {
  [ActionTypes.SET_ID](
    { commit }: AugmentedActionContext,
    payload: string | null
  ): void;

  [ActionTypes.SET_INSTANCE_ID](
    { commit }: AugmentedActionContext,
    payload: string | null
  ): void;

  [ActionTypes.SET_NAME](
    { commit }: AugmentedActionContext,
    payload: string
  ): void;

  [ActionTypes.SET_MODE](
    { commit }: AugmentedActionContext,
    payload: FormBuilderMode
  ): void;

  [ActionTypes.SET_STATUS](
    { commit }: AugmentedActionContext,
    payload: FormTemplateStatus
  ): void;

  [ActionTypes.SET_USERS](
    { commit }: AugmentedActionContext,
    payload: {
      id: string;
      name: string;
      roles: [FormBuilderUserRole];
    }[]
  ): void;

  [ActionTypes.SET_ACTIVE_STATE](
    { commit }: AugmentedActionContext,
    payload: boolean
  ): void;

  [ActionTypes.SET_SAVED_STATE](
    { commit }: AugmentedActionContext,
    payload: boolean
  ): void;

  [ActionTypes.SET_HIDE_BRANDING_STATE](
    { commit }: AugmentedActionContext,
    payload: boolean
  ): void;
}

export const actions: ActionTree<FormState, FormState> & Actions = {
  [ActionTypes.SET_ID](
    { commit }: AugmentedActionContext,
    payload: string | null
  ) {
    commit(MutationTypes.SET_ID, payload);
  },

  [ActionTypes.SET_INSTANCE_ID](
    { commit }: AugmentedActionContext,
    payload: string | null
  ) {
    commit(MutationTypes.SET_INSTANCE_ID, payload);
  },

  [ActionTypes.SET_NAME]({ commit }: AugmentedActionContext, payload: string) {
    commit(MutationTypes.SET_NAME, escapeString(payload));
  },

  [ActionTypes.SET_MODE](
    { commit }: AugmentedActionContext,
    payload: FormBuilderMode
  ) {
    commit(MutationTypes.SET_MODE, payload);
  },

  [ActionTypes.SET_STATUS](
    { commit }: AugmentedActionContext,
    payload: FormTemplateStatus
  ) {
    commit(MutationTypes.SET_STATUS, payload);
  },

  [ActionTypes.SET_USERS](
    { commit }: AugmentedActionContext,
    payload: {
      id: string;
      name: string;
      roles: [FormBuilderUserRole];
    }[]
  ) {
    commit(MutationTypes.SET_USERS, payload);
  },

  [ActionTypes.SET_ACTIVE_STATE](
    { commit }: AugmentedActionContext,
    payload: boolean
  ) {
    commit(MutationTypes.SET_ACTIVE_STATE, payload);
  },

  [ActionTypes.SET_SAVED_STATE](
    { commit }: AugmentedActionContext,
    payload: boolean
  ) {
    commit(MutationTypes.SET_SAVED_STATE, payload);
  },

  [ActionTypes.SET_HIDE_BRANDING_STATE](
    { commit }: AugmentedActionContext,
    payload: boolean
  ) {
    commit(MutationTypes.SET_HIDE_BRANDING_STATE, payload);
  },

  [ActionTypes.SET_SUBMITTER](
    { commit }: AugmentedActionContext,
    payload: { id: string; name: string; email: string }
  ) {
    commit(MutationTypes.SET_SUBMITTER, payload);
  },

  [ActionTypes.RESET_DATA]({ dispatch }: AugmentedActionContext) {
    dispatch(ActionTypes.SET_ID, null);
    dispatch(ActionTypes.SET_NAME, "");
    dispatch(ActionTypes.SET_MODE, null);
    dispatch(ActionTypes.SET_USERS, []);
    dispatch(ActionTypes.SET_ACTIVE_STATE, true);
    dispatch(ActionTypes.SET_SAVED_STATE, false);
    dispatch(ActionTypes.SET_HIDE_BRANDING_STATE, false);
    dispatch(ActionTypes.SET_INSTANCE_ID, null);
  },
};
