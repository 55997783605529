<template>
  <pds-progress-bar
    :value="value"
    :barColor="barColor"
    :transitionSec="transitionSec"
    v-if="value > 0"
  />
</template>

<script lang="ts">
import Vue from "vue";
import { Component, Watch } from "vue-property-decorator";
import { mapGetters } from "vuex";
import { ProgressBarComponent as PdsProgressBarComponent } from "@procesio/procesio-design-system";

@Component({
  components: {
    "pds-progress-bar": PdsProgressBarComponent,
  },
  computed: {
    ...mapGetters({
      isLoading: "isLoading",
    }),
  },
})
export default class ProgressBarComponent extends Vue {
  defaultTransitionSec = 0.5;

  value = 0;

  progressInterval: ReturnType<typeof setTimeout> | null = null;

  startProgressInterval() {
    this.clearProgressInterval();

    this.value = 1;
    this.progressInterval = setInterval(() => {
      if (this.value >= 100) {
        this.clearProgressInterval();
        return;
      }
      this.value += this.step;
    }, 100);
  }

  clearProgressInterval() {
    if (this.progressInterval) {
      clearInterval(this.progressInterval);
      setTimeout(() => (this.value = 0), 300);
    }
  }

  beforeDestroy() {
    this.clearProgressInterval();
  }

  @Watch("isLoading")
  onLoadingStateChange(isLoading: boolean, previousLoadingState: boolean) {
    if (!previousLoadingState && isLoading) {
      this.startProgressInterval();
    } else if (previousLoadingState && !isLoading) {
      // wait a moment to enjoy the progress bar
      setTimeout(() => {
        if (this.progressInterval && !this.isLoading) {
          this.value = 100;
          this.clearProgressInterval();
        }
      }, 300);
    }
  }

  get barColor() {
    if (this.value < 30) {
      return "#0038e3";
    } else if (this.value >= 30 && this.value <= 85) {
      return "#7039e3";
    } else if (this.value === 100) {
      return "#ffffff";
    } else {
      return "#0c285a";
    }
  }

  get step() {
    if (this.value <= 30) {
      return 9;
    } else if (this.value > 30 && this.value <= 70) {
      return 6;
    } else if (this.value > 70 && this.value <= 95) {
      return 4;
    } else if (this.value > 95 && this.value <= 99) {
      return 0.05;
    } else if (this.value > 99 && this.value <= 99.9) {
      return 0.001;
    } else {
      return 0;
    }
  }

  get transitionSec() {
    if (this.value < 30) {
      return 1.5;
    } else if (this.value >= 30 && this.value <= 80) {
      return 1;
    } else {
      return 0.4;
    }
  }
}
</script>
