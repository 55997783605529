import RestService from "../Rest.service";
import RestUrls from "../Rest.paths";
import storage from "@/utils/storage";
import { store } from "@/store";
import { ActionTypes as UserActionTypes } from "@/store/user/User.actions";
import { ActionTypes as DataModelActionTypes } from "@/store/dataModel/DataModel.actions";
import { config } from "@/config";
import { parseJwt } from "@/utils/parseJwt";

interface AuthBody {
  [username: string]: string;
  password: string;
}

interface RefreshBody {
  [token: string]: string;
  client_id: string;
}

interface LogOutBody {
  [token: string]: string;
  client_id: string;
}

export default class AuthService {
  static readonly CLIENT_ID: string = "procesio-ui";

  static baseUrl = config.api;

  static isSignedIn(): boolean {
    return !!store.getters.token;
  }

  /* eslint-disable */
  static signIn(email: string, password: string, code?: string) {
    /* eslint-disable */
    const json: AuthBody = {
      username: email,
      password: password,
      client_id: "procesio-ui",
    };

    if (code) {
      json["code"] = code;
    }

    const form = [];

    for (const property in json) {
      const encodedKey = encodeURIComponent(property);
      const encodedValue = encodeURIComponent(json[property]);
      form.push(encodedKey + "=" + encodedValue);
    }
    const formJoin = form.join("&");

    const headers = new Headers();

    headers.set("Accept", "application/json");
    headers.set("Content-type", "application/x-www-form-urlencoded");

    const url = `${RestService.endpoint}://${this.baseUrl}/${RestUrls.AUTH.AUTHENTIFICATE}`;

    let isBadRequest = false;
    let status = 0;

    return fetch(url, {
      method: "POST",
      headers,
      body: formJoin,
    })
      .then((res) => {
        status = res.status;
        isBadRequest = res.status === 400;

        const responseContentType = res.headers.get("content-type");

        if (
          responseContentType &&
          responseContentType.indexOf("application/json") !== -1
        ) {
          return res.json();
        } else {
          return res.blob();
        }
      })
      .then((res) =>
        RestService.createResponse<any>(res, isBadRequest, res.status)
      )
      .then(async (res) => {
        if (!res.is_error) {
          if (
            typeof res.content === "string" &&
            JSON.parse(res.content).error
          ) {
            return res;
          }

          await this.onLogin(res.content);
        }

        return res;
      })
      .then(async (res) => {
        await store.dispatch(UserActionTypes.LOAD_USER_ID);
        return res;
      })
      .catch(() => {
        // 407 = invalid credentials
        // 428 = mfa code required
        return RestService.createResponse<any>(
          null,
          true,
          status == 428 || status == 407 ? status : 401
        );
      });
  }

  static async refreshToken(signal?: AbortSignal | undefined) {
    await store.dispatch(UserActionTypes.SET_TOKEN_EXPIRES_IN, null);
    await store.dispatch(UserActionTypes.SET_REFRESH_TOKEN_EXPIRES_IN, null);

    const json: RefreshBody = {
      refresh_token: store.getters.refreshToken,
      client_id: "procesio-ui",
    };

    const form = [];

    for (const property in json) {
      const encodedKey = encodeURIComponent(property);
      const encodedValue = encodeURIComponent(json[property]);
      form.push(encodedKey + "=" + encodedValue);
    }
    const formJoin = form.join("&");

    const headers = new Headers();

    headers.set("Accept", "application/json");
    headers.set("Content-type", "application/x-www-form-urlencoded");

    const url = `${RestService.endpoint}://${this.baseUrl}/${RestUrls.AUTH.REFRESHTOKEN}`;

    let isBadRequest = false;

    return fetch(url, {
      method: "POST",
      headers,
      body: formJoin,
      signal,
    })
      .then((res) => {
        isBadRequest = res.status === 400;

        if (isBadRequest) {
          if (
            storage.get(storage.USER_KEY) &&
            storage.get(storage.PASSWORD_KEY)
          ) {
            return AuthService.signIn(
              storage.get(storage.USER_KEY),
              storage.get(storage.PASSWORD_KEY)
            );
          }

          store.dispatch(UserActionTypes.SET_TOKEN, null);

          return (window.location = "/" as unknown as Location);
        }

        const responseContentType = res.headers.get("content-type");

        if (
          responseContentType &&
          responseContentType.indexOf("application/json") !== -1
        ) {
          return res.json();
        } else {
          return res.blob();
        }
      })
      .then((res) => RestService.createResponse<any>(res, isBadRequest, 0))
      .then(async (res) => {
        if (!res.is_error) {
          await this.onLogin(res.content);
        }

        return res;
      })
      .catch(() => {
        return RestService.createResponse<any>(null, true, 401);
      });
  }

  static async onLogin(data: {
    access_token: string;
    expires_in: number;
    refresh_token: string;
    refresh_expires_in: number;
  }) {
    if (!data) {
      return;
    }

    await store.dispatch(
      UserActionTypes.SET_TOKEN_EXPIRES_IN,
      new Date().getTime() + data.expires_in * 1000
    );
    await store.dispatch(
      UserActionTypes.SET_REFRESH_TOKEN_EXPIRES_IN,
      new Date().getTime() + data.refresh_expires_in * 1000
    );
    await store.dispatch(UserActionTypes.SET_TOKEN, data.access_token!);
    await store.dispatch(UserActionTypes.SET_REFRESH_TOKEN, data.refresh_token);

    const tokenData = parseJwt(data.access_token);
    tokenData?.email && storage.save(storage.USER_KEY, tokenData.email);
  }

  static async createAccount(
    email: string,
    inviteToken?: string,
    firstName?: string,
    lastName?: string,
    userName?: string,
    referral: string | null = null,
    redirectQuery: any = {}
  ) {
    const response = await RestService.post<any>({
      url: RestUrls.AUTH.PASSWORD.CREATE,
      data: {
        firstName,
        lastName,
        email: typeof email === "string" ? email.toLowerCase() : email,
        userName,
        inviteToken,
        referral: referral && referral.length > 0 ? referral : null,
        extraData: redirectQuery,
      },
      baseUrl: this.baseUrl,
      isAuth: false,
    });

    return response;
  }

  static async updatePassword(token: string, password: string) {
    const response = await RestService.post<any>({
      url: RestUrls.AUTH.PASSWORD.UPDATE,
      data: { token, password },
      baseUrl: this.baseUrl,
      workspaced: false,
    });

    return response;
  }

  static async forgotPassword(email: string) {
    const response = await RestService.post<any>({
      url: RestUrls.AUTH.PASSWORD.FORGOT,
      data: { email },
      baseUrl: this.baseUrl,
      workspaced: false,
    });

    return response;
  }

  static async changePassword(
    clientId: string,
    token: string,
    oldPassword: string,
    newPassword: string
  ) {
    const response = await RestService.post<any>({
      url: RestUrls.AUTH.PASSWORD.CHANGE,
      data: { clientId, token, oldPassword, newPassword },
      baseUrl: this.baseUrl,
      workspaced: false,
    });

    return response;
  }

  static async resendToken(
    inviteToken: string,
    email: string,
    firstName?: string,
    lastName?: string,
    userName?: string
  ) {
    const response = await RestService.post<any>({
      url: RestUrls.AUTH.PASSWORD.RESEND_TOKEN,
      data: { inviteToken, email, firstName, lastName, userName },
      baseUrl: this.baseUrl,
      isAuth: false,
    });

    return response;
  }

  static async logOut() {
    const json: LogOutBody = {
      refresh_token: store.getters.refreshToken,
      client_id: this.CLIENT_ID,
    };

    const form = [];

    for (const property in json) {
      const encodedKey = encodeURIComponent(property);
      const encodedValue = encodeURIComponent(json[property]);
      form.push(encodedKey + "=" + encodedValue);
    }
    const formJoin = form.join("&");

    const headers = new Headers();
    headers.set("Accept", "application/json");
    headers.set("Content-type", "application/x-www-form-urlencoded");

    const url = `${RestService.endpoint}://${this.baseUrl}/${RestUrls.AUTH.LOGOUT}`;

    return fetch(url, {
      method: "POST",
      headers,
      body: formJoin,
    }).then((res) => {
      store.dispatch(UserActionTypes.SET_TOKEN, null);
      store.dispatch(UserActionTypes.SET_REFRESH_TOKEN, null);
      store.dispatch(UserActionTypes.SET_TOKEN_EXPIRES_IN, null);
      store.dispatch(UserActionTypes.SET_REFRESH_TOKEN_EXPIRES_IN, null);
      store.dispatch(DataModelActionTypes.RESET_DATA);

      storage.clear(storage.USER_KEY);
      storage.clear(storage.PASSWORD_KEY);

      return RestService.createResponse<any>(
        res,
        res.status === 400,
        res.status
      );
    });
  }

  static async authorizeMfa() {
    const response = await RestService.get<{ url: string }>({
      url: RestUrls.AUTH.OTP.AUTHORIZE,
      isAuth: false,
    });

    return response;
  }

  static async doOAuth2Callback(queryParams: any) {
    return RestService.get<{
      access_token: string;
      expires_in: number;
      refresh_token: string;
      refresh_expires_in: number;
    }>({
      url: RestUrls.AUTH.OTP.CALLBACK,
      baseUrl: this.baseUrl,
      queryParams,

      workspaced: false,
    });
  }
}
