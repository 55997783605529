import RestService, { PaginatedResponse } from "../Rest.service";
import RestUrls from "../Rest.paths";

import { SettingTab } from "@/modules/ProcessDesigner/components/PropertiesPanel/PropertiesPanel.model";
import { WebhookInstance } from "@/services/automation/webhook/Webhook.model";
import { config } from "@/config";
import { AnalyticsDetails } from "@/services/resources/Resource.model";
import { ActionResponseLazy } from "../actionlist/ActionList.service";
import { store } from "@/store";
import { NO_PROPERTIES_PANEL_ACTION_TEMPLATES_ID } from "@/utils/actionHelper";

export const DEFAULT_PROCESS_NAME = "Untitled process";

export interface Flow {
  id: string;
  status: number;
  title: string;
  description: string;
  actions: Action[] | null;
  variables: Variable[];
  isValid: boolean;
  active: boolean;
  submittedBy: string;
  createdOn: string;
  updatedOn: string;
  usedInSchedules?: boolean;
  usedInSubprocesses?: boolean;
  isNotification?: boolean;
  webhooks: WebhookInstance[];
  customResponse?: {
    value: string;
    variable: VariableParameter;
  } | null;
}

export interface ProcessGroup {
  id?: string;
  name: string;
  items: Flow[];
  separated?: boolean;
}

export interface Instance extends Flow {
  flow?: Flow;
  firstName?: string;
  lastName?: string;
  scheduleName?: string;
  webhookName?: string;
  actionsConsumed?: number;
  analyticsDetails?: AnalyticsDetails[];
  debugMode: boolean;
}

export const PROCESS_PREFIX_DELIMITER = "/";

export enum FlowUsedInType {
  USED_IN_SUBPROCESSES = 0,
  USED_IN_SCHEDULES = 1,
}

export enum VariableType {
  INPUT = 10,
  UNDEFINED = 20,
  OUTPUT = 30,
  CONTEXT = 100,
  PROCESS = 40,
}

export interface VariableAction {
  id: string;
  name: string;
  templateId: string;
}

export interface Variable {
  id: string;
  dataType: string;
  type: VariableType;
  name: string;
  defaultValue?: string | Record<string, any>;
  contextId?: string;
  isList: boolean;
  isRequired?: boolean;
  parentVariableId?: string | null;
  isDeleted?: boolean; // soft delete
  /**
   * used for:
   *  - webhook's event variable
   *  - forEach item variable
   *  - system variable
   */
  isReadonly?: boolean;
  isConst?: boolean;
  // Id of variable scopes. e.g. process id, document id which for variable is defined
  // Can be not defined or empty array - it means no scopes defined
  scopesId?: string[];
  actions?: VariableAction[]; // actions where variable is used
  isError?: boolean;
}

export interface AttributeParameter {
  attributeId: string;
  nextAttribute: AttributeParameter | null;
}

export interface VariableParameter {
  id: number;
  variableId: string;
  attribute: AttributeParameter | null;
}

export interface AttributeParameter {
  attributeId: string;
  nextAttribute: AttributeParameter | null;
}

export interface VariableParameter {
  id: number;
  variableId: string;
  attribute: AttributeParameter | null;
}

export interface Parameter {
  tabPropertyId: string;
  value: unknown;
  variable: VariableParameter[] | null;
}

export interface ActionData {
  breakPoint?: { enabled: boolean } | null;
  isDisabled?: boolean;
  canHaveBreakPoint?: boolean;
  hasBreakpoint?: boolean;
  canBeDisabled?: boolean;
}

export const initActionData = (action: Action) => {
  const template: ActionResponseLazy = store.getters.getActionById(
    action.templateId
  );

  return {
    canHaveBreakPoint: !!action.breakPoint || !!template?.isDebuggable,
    hasBreakpoint: !!action.breakPoint?.enabled,
    isDisabled: action.isDisabled || false,
    canBeDisabled: !NO_PROPERTIES_PANEL_ACTION_TEMPLATES_ID.includes(
      action.templateId
    ),
  };
};

export interface Action extends ActionData {
  id: string;
  flowId: string;
  templateId: string;
  actionName: string;
  actionTemplateName: string;
  type: string;
  status: number;
  categories?: string[];
  ports?: Port[];
  parentId?: string;
  // new properties
  customData: {
    name: string;
    description: string;
    icon: string;
    wasDropped: boolean;
    position: {
      x: number;
      y: number;
    };
    areaSize: {
      width: number;
      height: number;
      x: number;
      y: number;
    };
    configuration: SettingTab[];
    inputPorts: number;
    outputPorts: number;
    type: string;
    canDelete?: boolean;
    canDuplicate?: boolean;
    canSaveActionAsTemplate?: boolean;
    isTestable?: boolean;
  };
  parameters: Parameter[];
  variableErrorId?: string;
  isTestable?: boolean;
}

export interface Port {
  id: string;
  flowId: string;
  sourceId: string;
  destinationId: string;
  state: number;
  type: number;
  config: unknown;
  data: unknown;
  errors: unknown;
}

export type PublishFlowPayload = Array<{ id: string; value: any }>;

type FlowUsage = {
  errors: Array<unknown>;
  value: Array<{
    name: string;
    type: FlowUsedInType;
  }>;
};

export enum InstanceRunMode {
  DEFAULT = "DEFAULT",
  DEBUG = "DEBUG",
}

export enum InstanceDebugActionType {
  RESUME = 0,
  GO_TO_NEXT_STEP = 1,
}

export interface LaunchError {
  actionId: string;
  actionName: string;
  actionTemplateId: string;
  actionType: string;
  errorMessage: string;
}

export default class OrchestrationService {
  static certificate = config.protocol;

  static baseUrl = config.api;

  static loadFlow(flowId: string) {
    return RestService.get<Record<"flow", Flow>>({
      url: RestUrls.API.PROJECTS.FLOW(flowId),
      isAuth: true,
    });
  }

  static loadFlowRestricted(flowId: string) {
    return RestService.get<Record<"flow", Flow>>({
      url: RestUrls.API.PROJECTS.FLOW_RESTRICTED(flowId),
      isAuth: true,
    });
  }

  static loadInstances(flowId: string, pageNumber = 0, pageItemCount = 1000) {
    return RestService.get<Record<string, any>>({
      url: RestUrls.API.PROJECTS.INSTANCES(flowId, pageNumber, pageItemCount),
      isAuth: true,
    });
  }

  static loadInstancesHistory(
    flowId: string,
    pageNumber = 0,
    pageItemCount = 1000
  ) {
    return RestService.get<Record<string, any>>({
      url: RestUrls.API.PROJECTS.INSTANCESHISTORY(
        flowId,
        pageNumber,
        pageItemCount
      ),
      isAuth: true,
    });
  }

  static loadLegacyInstances(
    flowId: string,
    pageNumber = 0,
    pageItemCount = 1000
  ) {
    return RestService.get<Record<string, any>>({
      url: RestUrls.API.PROJECTS.LEGACY_INSTANCE(
        flowId,
        pageNumber,
        pageItemCount
      ),
      isAuth: true,
    });
  }

  static async getStatus(flowId: string, instanceId: string) {
    const request = (isArchived: boolean) =>
      RestService.get<{ instance: Instance }>({
        url: RestUrls.API.PROJECTS.STATUS(instanceId),
        isAuth: true,
        queryParams: { flowTemplateId: flowId, isArchived },
      });

    const nonArchivedInstanceResponse = await request(false);

    return nonArchivedInstanceResponse.is_error
      ? request(true)
      : nonArchivedInstanceResponse;
  }

  static async updateInstanceStatus(
    flowId: string,
    instanceId: string,
    data: Partial<Instance>
  ) {
    return RestService.put({
      url: RestUrls.API.PROJECTS.STATUS(instanceId),
      isAuth: true,
      queryParams: { flowTemplateId: flowId },
      data,
    });
  }

  static stopInstance(flowId: string, instanceId: string) {
    return RestService.post<Record<string, any>>({
      url: RestUrls.API.PROJECTS.STOPINSTANCE(instanceId),
      isAuth: true,
      data: {},
      queryParams: { flowTemplateId: flowId },
    });
  }

  static updateInstanceDebugActionType(
    instanceId: string,
    type: InstanceDebugActionType,
    connectionId?: string
  ) {
    return RestService.post({
      url: RestUrls.API.PROJECTS.INSTANCE_DEBUG_OPERATION_TYPE(instanceId),
      isAuth: true,
      data: { debuggerActionType: type, connectionId },
    });
  }

  static saveFlow(flow: Flow) {
    return RestService.post({
      url: RestUrls.API.PROJECTS.DEFAULT,
      isAuth: true,
      data: flow,
    });
  }

  static updateFlow(flow: Flow) {
    return RestService.put({
      url: RestUrls.API.PROJECTS.DEFAULT,
      isAuth: true,
      data: flow,
    });
  }

  static runFlow(flowId: string, body?: any[]) {
    return RestService.post<{ instanceId: string }>({
      url: RestUrls.API.PROJECTS.RUN(flowId),
      isAuth: true,
      data: body ? body : {},
    });
  }

  static publishFlow(flowId: string, body?: PublishFlowPayload) {
    return RestService.post<Flow>({
      url: RestUrls.API.PROJECTS.PUBLISH(flowId),
      isAuth: true,
      data: body ? body : {},
    });
  }

  static launchFlow(
    flowId: string,
    instanceId: string,
    connectionId?: string,
    runMode = InstanceRunMode.DEFAULT
  ) {
    return RestService.post({
      url: RestUrls.API.PROJECTS.LAUNCH(instanceId, runMode),
      isAuth: true,
      data: { connectionId, flowTemplateId: flowId },
    });
  }

  static getInputVariables(instanceId: string) {
    return RestService.get<Record<string, unknown>>({
      url: RestUrls.API.PROJECTS.PAYLOAD(instanceId),
      isAuth: true,
    });
  }

  static uploadFile(
    header: {
      flowTemplateId: string;
      flowInstanceId: string;
      variableName: string;
      fileId: string;
    },
    file: File
  ) {
    const formData = new FormData();
    formData.append("package", file);

    return RestService.post({
      url: RestUrls.API.PROJECTS.UPLOAD_FILE,
      isAuth: true,
      header: header,
      workspaced: true,
      data: formData,
    });
  }

  static async downloadFile(header: {
    uploadFilePath: string;
    variableId?: string;
    instanceId?: string;
    flowTemplateId?: string;
  }) {
    const request = (isArchived: boolean) =>
      RestService.get<File>({
        url: RestUrls.API.PROJECTS.DOWNLOAD_FILE,
        isAuth: true,
        workspaced: true,
        header: header,
        queryParams: { isArchived },
      });

    const nonArchivedInstanceFileResponse = await request(false);

    return nonArchivedInstanceFileResponse.is_error
      ? request(true)
      : nonArchivedInstanceFileResponse;
  }

  static loadAllFlow(pageNumber = 0, pageItemCount = 0) {
    return RestService.get<PaginatedResponse<Flow>>({
      url: RestUrls.API.PROJECTS.LIST_PAGINATED(pageNumber, pageItemCount),
      isAuth: true,
    });
  }

  static searchFLow() {
    return RestService.get<PaginatedResponse<Flow>>({
      url: "api/projects",
      isAuth: true,
    });
  }

  static deleteFlow(flowId: string) {
    return RestService.delete<{ payload: Flow[] }>({
      url: RestUrls.API.PROJECTS.FLOW(flowId),
      isAuth: true,
    });
  }

  static dupplicateFlow(flowId: string) {
    return RestService.post<{ hasWebhook: boolean }>({
      url: RestUrls.API.PROJECTS.DUPPLICATE(flowId),
      isAuth: true,
      data: {},
    });
  }

  static checkIfUsed(flowID: string) {
    return RestService.get<FlowUsage>({
      url: `api/Projects/${flowID}/used`,
      isAuth: true,
    });
  }

  static toggleActivation(flowID: string, isActive: boolean) {
    return RestService.patch<FlowUsage>({
      url: `api/projects/${flowID}/toggle-activation`,
      isAuth: true,
      header: {
        state: isActive,
      },
      data: {},
    });
  }

  static getCount() {
    return RestService.get<number>({
      url: RestUrls.API.PROJECTS.COUNT,
      isAuth: true,
    });
  }

  static loadAllScheduleRestrictedFlows() {
    return RestService.get<PaginatedResponse<Flow>>({
      url: RestUrls.API.PROJECTS.LIST_RESTRICTED + "/schedules",
      isAuth: true,
    });
  }

  static loadScheduleRestrictedFlow(flowId: string) {
    return RestService.get<Flow>({
      url: RestUrls.API.PROJECTS.FLOW_RESTRICTED(flowId) + "/schedules",
      isAuth: true,
    });
  }
}
