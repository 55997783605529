<template>
  <div
    :class="{
      'pup-c-group': true,
      'pup-c--holder': level === 0,
      'pds-u-m--b--16': level === 0,
      'pds-u-m--t--16': level > 0 && index === 0,
      'pup-c-group--check': isChecked || isEverythingSelected,
    }"
    :data-level="level"
  >
    <!-- HEADER -->
    <div
      class="pup-c-group--head pup-c-group--row"
      :class="{
        'pds-u-m--r--4 pds-u-m--l--4 pds-u-m--t--4':
          isChecked || isEverythingSelected,
      }"
    >
      <div v-if="!isAnyConditionChecked" class="pup-c-group--input">
        <pds-icon
          v-if="level > 0 && !disabled"
          icon="drag_indicator"
          :isOutlined="true"
          class="pup-c-case-configuration--icon pup-c-case-configuration--drag-handle"
        />
        <div @mouseover="hover = true" @mouseleave="hover = false">
          <span
            v-if="!hover && !isChecked && level > 0"
            class="pup-c-group--index pds-u-m--r--4"
            >{{ index + 1 }}</span
          >

          <pds-checkbox
            v-if="hover || isChecked"
            class="pup-c-group--index pds-u-m--r--4"
            variation="tiny"
            v-model="condition.checked"
          />
        </div>

        <p class="pds-u-subtitle--2 pds-u-m--r--4" v-if="!isEditName">
          {{ level === 0 ? "Advanced Filtering Builder" : name }}
        </p>

        <pds-input
          v-else
          v-model="condition.name"
          ref="renameInput"
          :status="errorStatus"
          @blur="changeIsEditName"
          :maxlength="32"
          variation="small"
          :disabled="disabled"
        />

        <pds-icon
          v-if="level > 0 && !isEditName && !disabled"
          icon="edit"
          class="pup-c-group--row--button material-icons--grey"
          v-tooltip="{
            content: language.rename,
          }"
          @click="changeIsEditName"
        />
      </div>

      <div v-else class="pup-c-group--input pup-c-group--input--level">
        <p class="pds-u-body--2">
          {{
            (conditionsSelected > 0
              ? conditionsSelected + " " + language.condition
              : "") +
            (conditionsSelected > 0 && groupSelected > 0 ? " and " : "") +
            (groupSelected > 0 ? groupSelected + " " + language.groups : "")
          }}
        </p>
        <pds-button
          type="link"
          color="danger"
          size="small"
          class="pds-u-m--l--24"
          @click="deselectLevel"
          v-if="!disabled"
          >{{ language.clear }}</pds-button
        >
        <div class="pup-c-group--actions" v-if="!disabled">
          <pds-button
            v-if="level === 0"
            type="solid"
            color="danger"
            size="small"
            @click="deleteSelected"
            >{{ language.delete }}</pds-button
          >
          <pds-button
            v-if="level === 0 && conditionsSelected === 0"
            type="outlined"
            color="subtle"
            size="small"
            @click="ungroupSelectedGeneral"
            >{{ language.condition_builder_defaul_ungroup }}</pds-button
          >
          <pds-button
            v-if="
              level === 0 &&
              isSelectedOnSameLevel &&
              groupSelected + conditionsSelected > 1
            "
            type="outlined"
            color="subtle"
            size="small"
            @click="groupSelectedGeneral"
            >{{ language.condition_builder_defaul_create }}</pds-button
          >
        </div>
      </div>

      <div class="pup-c-group--buttons" v-if="!disabled">
        <div class="pup-c-group--buttons">
          <pds-button
            v-if="level > 0"
            type="outlined"
            color="subtle"
            size="small"
            @click="$emit('ungroup', index)"
            >{{ language.condition_builder_defaul_ungroup }}</pds-button
          >
          <pds-button
            v-if="level > 0"
            type="solid"
            color="danger"
            size="small"
            class="pds-u-m--l--12"
            @click="$emit('remove-condition', index)"
            >{{ language.condition_builder_defaul_delete }}</pds-button
          >
        </div>
      </div>
    </div>
    <!-- HEADER -->

    <!-- CONTENT -->
    <div class="pup-c-condition-builder">
      <draggable
        :list="condition.value"
        :group="{ name: 'g1' }"
        :scroll-sensitivity="200"
        :forceFallback="true"
        :move="onConditionMove"
        :options="{ disabled: disabled }"
        handle=".pup-c-case-configuration--drag-handle"
      >
        <component
          class="pup-c-group--item"
          v-for="(condition, key) in conditions"
          :is="getComponent(condition)"
          :key="condition.uid"
          :index="key"
          :settings="settings"
          :parent="parent"
          :processVariables="processVariables"
          :form="form"
          :condition="condition"
          :conditionsLength="conditions.length || 0"
          :level="getNextLevel(condition)"
          :status="status"
          :name="condition.name"
          :initialStatus="initialStatus"
          :disabled="disabled"
          :hasLogicOperatorDivider="
            conditions &&
            !condition.value &&
            conditions[key + 1] &&
            !conditions[key + 1].value
          "
          :operators="operators"
          @remove-condition="removeCondition"
          @ungroup="ungroup"
          :class="[
            conditions &&
              disabled &&
              key === conditions.length - 1 &&
              !!condition.value &&
              'pds-u-m--b--16',
          ]"
        />
      </draggable>

      <pup-cb-adder
        v-if="!disabled"
        :level="level"
        @add-condition="addCondition"
        @add-group="addGroup"
      />

      <pup-logic-operator
        v-if="canShowLogicOperator(index)"
        v-model="condition.logicOperator"
        :disabled="disabled"
        :hasDivider="false"
      />
    </div>
    <!-- CONTENT -->
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import { Prop, Component, Emit, Model } from "vue-property-decorator";

import {
  IconComponent,
  ButtonComponent,
  InputComponent,
  CheckboxComponent,
  PdsTypes,
} from "@procesio/procesio-design-system";
import CollapsableSlot from "@/components/Collapsable/CollapsableSlot.component.vue";
import {
  Node,
  Setting,
} from "@/modules/ProcessDesigner/components/PropertiesPanel/PropertiesPanel.model";
import { ProcessVariable } from "@/services/processvariables/ProcessVariables.model";
import { FormBuilder } from "@/utils/ReactiveForm";
import LogicOperator from "@/modules/ProcessDesigner/components/Controls/ConditionBuilder/LogicOperator/LogicOperator.component.vue";
import ConditionRow from "../ConditionRow/ConditionRow.component.vue";
import AddRow from "../AddRow/AddRow.component.vue";
import Language from "@/utils/locale/en.json";
import draggable from "vuedraggable";
import { createGuid } from "@/utils/type/guid";
import { Condition } from "@/services/condition/Condition.model";
import { Operator } from "@/services/actionlist/ActionList.service";

@Component({
  components: {
    "pup-collapsable": CollapsableSlot,
    "pds-icon": IconComponent,
    "pds-button": ButtonComponent,
    "pds-input": InputComponent,
    "pup-logic-operator": LogicOperator,
    draggable,
    "pup-cb-adder": AddRow,
    "condition-row": ConditionRow,
    "pds-checkbox": CheckboxComponent,
  },
  name: "condition-builder",
})
export default class ConditionGroup extends Vue {
  @Model("update-name") name!: string;

  @Model("update-conditions") condition!: Condition;

  @Prop() conditionsLength!: number;

  @Prop({ default: 0 }) index!: number;

  @Prop({ default: 0 }) level!: number;

  @Prop() settings!: Setting;

  @Prop() parent!: Node;

  @Prop() processVariables!: ProcessVariable[];

  @Prop() form!: FormBuilder;

  @Prop() isEverythingSelected?: boolean;

  @Prop({ default: () => ({}) }) status!: {
    [key: string]: PdsTypes.InputStatus;
  };

  @Prop({ default: () => ({}) }) initialStatus!: {
    [key: string]: PdsTypes.InputStatus;
  };

  @Prop({ default: false, type: Boolean }) disabled?: boolean;

  @Prop({}) operators!: Operator[];

  blurredFields: Set<string> = new Set();

  language = Language;

  isEditName = false;

  errorStatus = {
    type: "danger",
    message: "",
  };

  hover = false;

  maxLevelIndex = 3; // 4: starts from 0

  get conditions() {
    return this.condition.value;
  }

  get isChecked() {
    return this.condition?.checked;
  }

  get isAnyConditionChecked() {
    const conditions = this.condition.value?.filter(
      (condition) => condition.checked
    );
    return (
      (conditions && conditions.length > 0 && this.level === 0) ||
      this.isDeepSelected
    );
  }

  get currentConditions() {
    return this.condition.value || [];
  }

  get isDeepSelected() {
    const selectedLevels = this.getSelectedLevels;
    return (
      (!this.isSelectedOnSameLevel && this.level === 0) ||
      (selectedLevels[0] != 0 && this.level === 0 && selectedLevels.length > 0)
    );
  }

  get conditionsSelected() {
    let sum = 0;
    const findSelectedConditionsOnAllLevels = (conditions: Condition[]) => {
      const sumOnLevel = conditions.filter(
        (condition) =>
          condition.checked && condition.leftOperator && condition.rightOperator
      ).length;

      sum = sum + sumOnLevel;
      conditions.forEach((condition) => {
        if (condition.value && condition.value?.length > 0) {
          findSelectedConditionsOnAllLevels(condition.value);
        }
      });
    };

    findSelectedConditionsOnAllLevels(this.currentConditions);

    return sum;
  }

  get groupSelected() {
    let sum = 0;
    const findSelectedConditionsOnAllLevels = (conditions: Condition[]) => {
      const sumOnLevel = conditions.filter(
        (condition) =>
          condition.checked &&
          !condition.leftOperator &&
          !condition.rightOperator
      ).length;

      sum = sum + sumOnLevel;
      conditions.forEach((condition) => {
        if (condition.value && condition.value?.length > 0) {
          findSelectedConditionsOnAllLevels(condition.value);
        }
      });
    };

    findSelectedConditionsOnAllLevels(this.currentConditions);

    return sum;
  }

  get isSelectedOnSameLevel() {
    const levels: number[] = [];
    const isSelectedOnLevel = (conditions: Condition[], index: number) => {
      const isSelected =
        conditions.filter((condition) => condition.checked).length > 0;

      if (isSelected) {
        levels.push(index);
      }

      conditions.forEach((condition) => {
        if (condition.value && condition.value?.length > 0) {
          isSelectedOnLevel(condition.value, index + 1);
        }
      });
    };

    isSelectedOnLevel(this.currentConditions, 0);
    return levels.length < 2;
  }

  get getSelectedLevels() {
    const levels: number[] = [];
    const isSelectedOnLevel = (conditions: Condition[], index: number) => {
      const isSelected =
        conditions.filter((condition) => condition.checked).length > 0;

      if (isSelected) {
        levels.push(index);
      }

      conditions.forEach((condition) => {
        if (condition.value && condition.value?.length > 0) {
          isSelectedOnLevel(condition.value, index + 1);
        }
      });
    };

    isSelectedOnLevel(this.currentConditions, 0);
    return levels;
  }

  @Emit("close")
  closeSidePanel(e: MouseEvent) {
    return e;
  }

  @Emit("save")
  saveSidePanel(e: MouseEvent) {
    return e;
  }

  @Emit("update-value")
  deselectLevel() {
    const deselectConditions = (conditions: Condition[]) => {
      conditions.forEach((condition) => {
        this.$set(condition, "checked", false);

        if (condition.value && condition.value?.length > 0) {
          deselectConditions(condition.value);
        }
      });
    };

    deselectConditions(this.currentConditions);
  }

  @Emit("update-value")
  deleteLevelSelected() {
    return this.currentConditions.filter((condition) => !condition.checked);
  }

  getNextLevel(condition: Condition) {
    if (condition.value) {
      return this.level + 1;
    }
    return this.level;
  }

  getComponent(condition: Condition) {
    if (condition.value) {
      return "condition-builder";
    }
    return "condition-row";
  }

  checkedMove() {
    return this.level !== 0;
  }

  changeIsEditName() {
    this.isEditName = !this.isEditName;

    if (this.isEditName) {
      this.$nextTick(() => {
        const inputComponent = this.$refs["renameInput"] as Vue;
        if (inputComponent) {
          const input = inputComponent.$el.querySelector("input");

          if (input) {
            input.focus();
          }
        }
      });
    }
  }

  addCondition() {
    this.condition.value?.push({
      id: this.currentConditions.length,
      uid: createGuid(),
      operator: null,
      leftOperator: {
        variable: "",
        attribute: {
          id: "",
          nextAttribute: null,
        },
        value: "",
      },
      rightOperator: {
        variable: "",
        attribute: {
          id: "",
          nextAttribute: null,
        },
        value: "",
      },
      auxOperator: {
        variable: "",
        attribute: {
          id: "",
          nextAttribute: null,
        },
        value: "",
      },
      logicOperator: 0,
      value: null,
    });
  }

  addGroup() {
    const newGroup = {
      id: 0,
      uid: createGuid(),
      name: Language.condition_builder_defaul_name,
      operator: NaN,
      leftOperator: null,
      rightOperator: null,
      auxOperator: null,
      logicOperator: 0,
      value: [],
    };

    this.condition.value?.push(newGroup);
  }

  removeCondition(key: number) {
    this.condition.value?.splice(key, 1);
  }

  ungroup(index: number) {
    if (this.currentConditions[index]) {
      const value = this.currentConditions[index].value;
      if (value) {
        this.condition.value?.splice(index, 1, ...value);
      }
    }
  }

  deleteSelected() {
    const deleteSelectedItems = (conditions: Condition[]) => {
      const newCondition = conditions.filter((condition) => !condition.checked);

      conditions.forEach((condition) => {
        if (condition.value) {
          condition.value = deleteSelectedItems(condition.value);
        }
      });

      return newCondition;
    };

    this.condition.value?.splice(
      0,
      this.currentConditions.length,
      ...deleteSelectedItems(this.currentConditions)
    );
  }

  groupSelectedGeneral() {
    const accumulator: Condition[] = [];
    const groupSelected = (conditions: Condition[], level: number) => {
      const checkedConditions = conditions
        .filter((condition) => condition.checked)
        .map((condition) => {
          return {
            ...condition,
            checked: false,
          };
        });

      const uncheckedConditions = conditions.filter(
        (condition) => !condition.checked
      );

      checkedConditions.forEach((condition) => {
        const value = condition.value;
        if (value) {
          accumulator.push(...value);
        } else {
          accumulator.push(condition);
        }
      });

      if (level === this.getSelectedLevels[0]) {
        uncheckedConditions.push({
          id: 0,
          name: Language.condition_builder_defaul_name,
          operator: null,
          leftOperator: null,
          rightOperator: null,
          auxOperator: null,
          logicOperator: 0,
          value: accumulator,
        });
      }

      conditions.forEach((condition) => {
        if (condition.value) {
          // groupSelected(condition.value, level + 1);
          condition.value.splice(
            0,
            this.currentConditions.length,
            ...groupSelected(condition.value, level + 1)
          );
        }
      });

      return uncheckedConditions;
    };

    this.condition.value?.splice(
      0,
      this.currentConditions.length,
      ...groupSelected(this.currentConditions, 0)
    );
  }

  ungroupSelectedGeneral() {
    const upgroupSelected = (conditions: Condition[], level: number) => {
      conditions.forEach((condition, index) => {
        if (condition.checked) {
          const value = condition.value;
          if (value) {
            conditions.splice(index, 1, ...value);
          }
        }
        if (condition.value) {
          upgroupSelected(condition.value, level + 1);
        }
      });
    };

    upgroupSelected(this.currentConditions, 0);
  }

  canShowLogicOperator(index: number) {
    return index !== this.conditionsLength - 1 && this.level > 0;
  }

  onConditionMove(onMoveEvent: any) {
    const draggedContext = onMoveEvent.draggedContext;
    const relatedLevel = onMoveEvent.related.dataset.level || 0;

    if (!Array.isArray(draggedContext.element.value)) {
      return true;
    }

    return relatedLevel < this.maxLevelIndex;
  }
}
</script>

<style lang="scss">
@import "./ConditionGroup.component.scss";
</style>
